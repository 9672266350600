import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from "ng-apexcharts";
import { IMqttMessage, MqttModule, IMqttServiceOptions} from "ngx-mqtt";
import { environment } from 'src/environments/environment';
import { IndoorComponent } from './indoor/indoor.component';
import { IndoorsComponent } from './indoors/indoors.component';
import { HttpClientModule } from '@angular/common/http';


export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'device.petalred.com',
  port: 8084,
  protocol: 'wss',
  path: '/mqtt'
}
@NgModule({
  declarations: [
    AppComponent,
    IndoorComponent,
    IndoorsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgChartsModule,
    NgApexchartsModule,
    HttpClientModule,
    MqttModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
