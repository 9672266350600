import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndoorComponent } from './indoor/indoor.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { IndoorsComponent } from './indoors/indoors.component';
const routes: Routes = [
  {
    path:'',
component:IndoorsComponent,
  },
  {
    path:':id',
component:IndoorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class AppRoutingModule { }
