
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexYAxis,
  ApexXAxis,
  ApexMarkers,
  ApexGrid,
  ApexFill
} from "ng-apexcharts";
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  markers: ApexMarkers;
  grid: ApexGrid;
  fill: ApexFill;
  colors: string[];
  legend: any;
};

@Component({
  selector: 'app-indoors',
  templateUrl: './indoors.component.html',
  styleUrls: ['./indoors.component.scss']
})
export class IndoorsComponent implements OnInit {


  @ViewChild("chart", { static: true }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  private subscription: Subscription;
  indoor: any = [];
  data: any = [];
  datas: any = [];
  topic: any;


  constructor(private _mqttService: MqttService, private activatedRoute: ActivatedRoute) {
    _mqttService.connect({ username: 'PetalHosUser', password: 'PetalHosEmq@20#19!' });

    this.chartOptions = {
      series: [{
        name: "Indoor",
        data: [this.data]
      }
      ],

      chart: {
        height: 850,
        type: 'scatter',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false,
          type: 'xy'
        }
      },
      xaxis: {
        tickAmount: 10,
        min: 0,
        max: 10,
        labels: {
          formatter: function (val) {
            return parseFloat(val).toFixed(1)
          },
        },
        floating: true,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
      },
      colors: ["#4D72AA", "#9DD6FF"],
      markers: {
        size: 20
      },
      yaxis: {
        tickAmount: 14.7,
        min: 0,
        max: 14.7,

        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        labels: {
          show: false
        }
      },
      fill: {
        type: "image",
        opacity: 1,
        image: {
          src: [
            "https://freesvg.org/img/Simple-Water-Icon.png",
            "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Facebook_circle_pictogram.svg/512px-Facebook_circle_pictogram.svg.png"
          ],
          width: 40,
          height: 40
        }
      },
      legend: {
        labels: {
          useSeriesColors: true
        },
        markers: {
          customHTML: [
            function () {
              return '<span><i class="fab fa-facebook"></i></span>';
            },
            function () {
              return '<span><i class="fab fa-instagram"></i></span>';
            }
          ]
        }
      },
      grid: {
        show: false,
      }
    };

  }
  ngOnInit(): void {
    const allParams = this.activatedRoute.snapshot.params // allParams is an object
    console.log("Params")
    console.log(allParams) // retrieve the parameter "param1"
    this.subscribeNewTopic();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeNewTopic(): void {
    this.topic = this.activatedRoute.snapshot.params["id"];
    console.log("inside subscribe new topic", this.topic);
    this.subscription = this._mqttService
      .observe("/INDOOR/" + this.topic)
      .subscribe((message: IMqttMessage) => {
        this.indoor = (message.payload.toString());
        this.datas.push(JSON.parse(this.indoor));
        this.datas.forEach((element: any) => {
          this.data = [];
          this.data.push(element.xData, element.yData);

        });
        this.chartOptions.series = [{
          data: [this.data]
        }];
      })
  }

}
