import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, interval } from 'rxjs';
// import 'rxjs/add/observable/interval';
// import 'rxjs/add/operator/startWith';
// import 'rxjs/add/operator/switchMap';
import { HttpClient } from '@angular/common/http';


import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexYAxis,
  ApexXAxis,
  ApexMarkers,
  ApexGrid,
  ApexFill
} from "ng-apexcharts";
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  markers: ApexMarkers;
  grid: ApexGrid;
  fill: ApexFill;
  colors: string[];
  legend: any;
};

@Component({
  selector: 'app-indoor',
  templateUrl: './indoor.component.html',
  styleUrls: ['./indoor.component.scss']
})
export class IndoorComponent implements OnInit {
  @Input() products$: Observable<any>;

  @ViewChild("chart", { static: true }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  private subscription: Subscription;
  indoor: any = [];
  data: any = [];
  datas: any = [];
  topic: any;
  interval: any;


  constructor(private _mqttService: MqttService, private activatedRoute: ActivatedRoute, private http: HttpClient) {
    _mqttService.connect({ username: 'PetalHosUser', password: 'PetalHosEmq@20#19!' });

    this.chartOptions = {
      series: [{
        // name: "Indoor",
        data: [this.data]
      }
      ],

      chart: {
        height: 850,
        type: 'scatter',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false,
          type: 'xy'
        }
      },
      xaxis: {
        tickAmount: 10,
        min: 0,
        max: 10,
        labels: {
          formatter: function (val) {
            return parseFloat(val).toFixed(1)
          },
        },
        floating: true,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
      },
      colors: ["#4D72AA", "#9DD6FF"],
      markers: {
        size: 20
      },
      yaxis: {
        tickAmount: 14.7,
        min: 0,
        max: 14.7,

        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        labels: {
          show: false
        }
      },
      fill: {
        type: "image",
        opacity: 1,
        image: {
          src: [
            "https://freesvg.org/img/Simple-Water-Icon.png",
            "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Facebook_circle_pictogram.svg/512px-Facebook_circle_pictogram.svg.png"
          ],
          width: 40,
          height: 40
        }
      },
      legend: {
        labels: {
          useSeriesColors: true
        },
        markers: {
          customHTML: [
            function () {
              return '<span><i class="fab fa-facebook"></i></span>';
            },
            function () {
              return '<span><i class="fab fa-instagram"></i></span>';
            }
          ]
        }
      },
      grid: {
        show: false,
      }
    };

  }
  ngOnInit(): void {
    const allParams = this.activatedRoute.snapshot.params // allParams is an object
    // console.log("Params")
    // this.subscribeNewTopic();

    // this.products$ = Observable.interval(1000)
    //   .startWith(0)
    //   .switchMap(() => this.getIndoorData());
    // let x = 20.25; let y = 10.56
    this.interval = interval(3000).subscribe(x => {
      console.log(111, this.data)
      this.getIndoorData().subscribe((item: any) => {
        if (item.status) {
          // data?.data.forEach((element: any) => {
          this.data = [];
          // this.data.push(x,y);
          // x+=1; y+=1;
          this.data.push(item.data.x, item.data.y);
          // });
          this.chartOptions.series = [{
            data: [this.data]
          }];
        }
      });
    })

    // console.log(this.chartOptions.series)
  }

  getIndoorData() {
    let mac_id = this.activatedRoute.snapshot.params["id"];
    return this.http.post('http://petalmop.ddns.net:5000/getbeacons', { mac_id });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.interval)
  }

  subscribeNewTopic(): void {
    this.topic = this.activatedRoute.snapshot.params["id"];
    console.log("/INDOOR/" + this.topic);
    this.subscription = this._mqttService
      .observe("/INDOOR/" + this.topic)
      .subscribe((message: IMqttMessage) => {
        this.indoor = (message.payload.toString());
        this.datas.push(JSON.parse(this.indoor));
        this.datas.forEach((element: any) => {
          this.data = [];
          this.data.push(element.xData, element.yData);

        });
        this.chartOptions.series = [{
          data: [this.data]
        }];
      })
  }
}
